@keyframes colorTransitionLight {
  0% {
    background-color: #f7f7f8;
  }
  50% {
    background-color: #efeff1;
  }
  100% {
    background-color: #f7f7f8;
  }
}

@keyframes colorTransitionDark {
  0% {
    background-color: #ececee;
  }
  50% {
    background-color: #d6d8dc;
  }
  100% {
    background-color: #ececee;
  }
}

.loading-animation {
  @apply transition-colors;
  animation: colorTransitionLight 3s infinite;
}

.loading-animation-dark {
  @apply transition-colors;
  animation: colorTransitionDark 3s infinite;
}
