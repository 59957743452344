.Toastify__toast-container {
  @apply p-0;
  @apply w-auto;
  @apply rounded-full;
}

.Toastify__toast {
  @apply rounded-full;
  @apply p-0;
}

.Toastify__close-button {
  @apply hidden;
}

.Toastify__toast-body {
  @apply p-0;
}
