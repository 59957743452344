:root {
    --rte-hr: #dcdcdc;
    --placeholder-color: #adb5bd;
}

.editor-content {
    overflow-wrap: anywhere !important;
}

.editor-content .ProseMirror .heading-node {
    @apply relative font-semibold !important;
}

.editor-content .ProseMirror .heading-node:first-child {
    @apply mt-0 !important;
}

.editor-content .ProseMirror h1 {
    @apply mb-4 mt-[46px] text-[1.375rem] leading-7 tracking-[-0.004375rem] !important;
}

.editor-content .ProseMirror h2 {
    @apply mb-3.5 mt-8 text-[1.1875rem] leading-7 tracking-[0.003125rem] !important;
}

.editor-content .ProseMirror h3 {
    @apply mb-3 mt-6 text-[1.0625rem] leading-6 tracking-[0.00625rem] !important;
}

.editor-content .ProseMirror a.link {
    @apply cursor-pointer text-primary !important;
}

.editor-content .ProseMirror a.link:hover {
    @apply underline !important;
}

.editor-content .ProseMirror {
    @apply flex max-w-full flex-1 cursor-text flex-col !important;
    @apply z-0 outline-0 !important;
}

.editor-content .ProseMirror .block-node:not(:last-child),
.editor-content .ProseMirror .list-node:not(:last-child),
.editor-content .ProseMirror .text-node:not(:last-child) {
    @apply mb-2.5 !important;
}

.editor-content .ProseMirror li .text-node:has(+ .list-node),
.editor-content .ProseMirror li > .list-node,
.editor-content .ProseMirror li > .text-node,
.editor-content .ProseMirror li p {
    @apply mb-0 !important;
}

.editor-content .ProseMirror .ProseMirror-gapcursor {
    @apply pointer-events-none absolute hidden !important;
}

.editor-content .ProseMirror .ProseMirror-hideselection {
    @apply caret-transparent !important;
}

.editor-content .ProseMirror.resize-cursor {
    @apply cursor-col-resize !important;
}

.editor-content .ProseMirror .selection {
    @apply inline-block !important;
}

.editor-content .ProseMirror .selection,
.editor-content .ProseMirror *::selection,
::selection {
    @apply bg-primary/25 !important;
}

/* Override native selection when custom selection is present */
.editor-content .ProseMirror .selection::selection {
    background: transparent !important;
}

.editor-content .ProseMirror .is-empty::before {
    @apply pointer-events-none float-left h-0 w-full text-[var(--placeholder-color)] !important;
}

.editor-content .ProseMirror {
    @apply flex max-w-full flex-1 cursor-text flex-col !important;
    @apply z-0 outline-0 !important;
}

.editor-content .ProseMirror > div.editor {
    @apply block flex-1 whitespace-pre-wrap !important;
}

.editor-content .ProseMirror .block-node:not(:last-child),
.editor-content .ProseMirror .list-node:not(:last-child),
.editor-content .ProseMirror .text-node:not(:last-child) {
    @apply mb-2.5 !important;
}

.editor-content .ProseMirror ol,
.editor-content .ProseMirror ul {
    @apply pl-6 !important;
}

.editor-content .ProseMirror blockquote,
.editor-content .ProseMirror dl,
.editor-content .ProseMirror ol,
.editor-content .ProseMirror p,
.editor-content .ProseMirror pre,
.editor-content .ProseMirror ul {
    @apply m-0 !important;
}

.editor-content .ProseMirror li {
    @apply leading-7 !important;
}

.editor-content .ProseMirror li .text-node:has(+ .list-node),
.editor-content .ProseMirror li > .list-node,
.editor-content .ProseMirror li > .text-node,
.editor-content .ProseMirror li p {
    @apply mb-0 !important;
}

.editor-content .ProseMirror blockquote {
    @apply relative pl-3.5 !important;
}

.editor-content .ProseMirror blockquote::before,
.editor-content .ProseMirror blockquote.is-empty::before {
    @apply absolute bottom-0 left-0 top-0 h-full w-1 rounded-sm bg-primary content-[''] !important;
}

.editor-content .ProseMirror hr {
    @apply my-3 h-0.5 w-full border-none bg-[var(--rte-hr)] !important;
}

.editor-content .ProseMirror-focused hr.ProseMirror-selectednode {
    @apply rounded-full outline outline-2 outline-offset-1 !important;
}

.autocomplete-dropdown {
    background: #fff;
    border: 1px solid #3d25140d;
    border-radius: 0.7rem;
    box-shadow:
        0px 12px 33px 0px rgba(0, 0, 0, 0.06),
        0px 3.618px 9.949px 0px rgba(0, 0, 0, 0.04);
    display: flex;
    flex-direction: column;
    gap: 0.1rem;
    overflow: auto;
    padding: 0.4rem;
    position: relative;
}

.autocomplete-dropdown button:hover,
.autocomplete-dropdown button:hover.is-selected {
    background-color: rgba(61, 37, 20, 0.12);
}

.autocomplete-dropdown button.is-selected {
    background-color: rgba(61, 37, 20, 0.08);
}

.character-count {
    align-items: center;
    color: var(--gray-5);
    display: flex;
    font-size: 0.75rem;
    gap: 0.5rem;
}

.character-count svg {
    color: #ff600d;
}

.character-count--warning {
    color: red;
}

.character-count--warning svg {
    color: red;
}
